import { Grid } from '@mui/material';
import AboutUsPageHeader from '../components/page-header';
import GroupsIcon from '@mui/icons-material/Groups';
import { Helmet } from 'react-helmet-async';
import { AboutUsForm } from '../components/about-us-form';

export const AboutUsContainer = () => {
  return (
    <>
      <Helmet>
        <title>Haqqımızda - Mediaboard.az</title>
      </Helmet>
      <Grid
        container
        sx={{
          p: { xs: 2, sm: 4 }
        }}
        spacing={2}
      >
        <Grid item xs={12}>
          <AboutUsPageHeader icon={GroupsIcon} title="Haqqımızda" />
        </Grid>
        <Grid item xs={12}>
          <AboutUsForm />
        </Grid>
      </Grid>
    </>
  );
};
