import { useState } from "react";
import { Typography, Box, Divider } from "@mui/material";

import Text from "src/shared/components/Text";

import General from "./General";
import Price from "./price";
import { MapContainerType } from "../../types";

const Details = ({ data }: { data: MapContainerType }) => {
  return (
    <Box
      flex={1}
      sx={{
        p: { xs: 2, sm: 4 },
      }}
    >
      <Typography
        variant="h3"
        sx={{
          pt: 2,
          fontSize: { xs: 18, sm: 25 },
        }}
        component="h3"
      >
        {data.type}
      </Typography>
      <Divider
        sx={{
          mt: 2,
        }}
      />
      <General data={data} />
      <Divider
        sx={{
          mb: 2,
        }}
      />
      <Box sx={{ my: { xs: 1, sm: 0 } }}>
        <Typography component="div" variant="caption" gutterBottom>
          Ünvan
        </Typography>

        <Typography
          variant="h3"
          sx={{ span: { fontSize: { xs: 20, md: 25 } } }}
        >
          <Text>{data.address}</Text>
        </Typography>
      </Box>
      <Divider
        sx={{
          my: 2,
        }}
      />
      <Price data={data} />
    </Box>
  );
};
export default Details;
