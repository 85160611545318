import type { ReactNode } from 'react';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';
import WorkIcon from '@mui/icons-material/Work';
import BallotIcon from '@mui/icons-material/Ballot';
import GroupsIcon from '@mui/icons-material/Groups';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Ümumi',
    items: [
      {
        name: 'Reklam daşıyıcıları',
        icon: FormatListNumberedIcon,
        link: '/dashboard/containers'
      },
      {
        name: 'Daşıyıcı əlavə et',
        icon: AddIcon,
        link: '/dashboard/add-container'
      },
      {
        name: 'Haqqımızda',
        icon: GroupsIcon,
        link: '/dashboard/about-us'
      },
      {
        name: 'İstifadəçilər',
        icon: GroupIcon,
        link: '/dashboard/users'
      },
      {
        name: 'Şirkətlər',
        icon: WorkIcon,
        link: '/dashboard/compaines'
      },
      {
        name: 'Sifarişlər',
        icon: BallotIcon,
        link: '/dashboard/orders'
      }
    ]
  }
];

export default menuItems;
