import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/shared/components/SuspenseLoader';
import { PartialRouteObject } from 'react-router';

import Authenticated from 'src/shared/components/Authenticated';
import Layout from 'src/layouts';

import dashboardsRoutes from './dashboards';
import accountRoutes from './account';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const NotFound = Loader(lazy(() => import('src/modules/status/pages/404')));

const router: PartialRouteObject[] = [
  {
    path: '/',
    children: accountRoutes
  },
  {
    path: '/dashboard',
    element: (
      <Authenticated>
        <Layout />
      </Authenticated>
    ),
    children: [...dashboardsRoutes]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default router;
