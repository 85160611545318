import { Grid, Card, Skeleton, Box, Divider } from '@mui/material';

import billboardMappingImageData from '../utilities/billboard-mapping-image-data';

import Slider from './slider';
import Details from './Details';
import Location from './location';
import { MapContainerType } from '../types';

const ProductBody = ({ data }: { data: MapContainerType }) => {
  const images = billboardMappingImageData(data);
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Card>
          <Grid container spacing={0}>
            <Grid
              xs={12}
              md={6}
              item
              sx={{
                position: 'relative'
              }}
            >
              {!data ? (
                <Box p={1}>
                  <Skeleton
                    width="100%"
                    height="700px"
                    sx={{ transform: `scale(1)`, p: 2 }}
                  />
                </Box>
              ) : (
                <Slider images={images} />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {!data ? (
                <Box p={1}>
                  <Skeleton
                    width="100%"
                    height="700px"
                    sx={{ transform: `scale(1)`, p: 2 }}
                  />
                </Box>
              ) : (
                <Details data={data} />
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {!data ? (
          <Box p={1}>
            <Skeleton
              width="100%"
              height="70px"
              sx={{ transform: `scale(1)`, p: 2 }}
            />
          </Box>
        ) : (
          <Card>
            <Divider />
            <Location coordinates={data.coordinates} />
          </Card>
        )}
      </Grid>
    </Grid>
  );
};
export default ProductBody;
