import * as Yup from 'yup';
import type { FC } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Link,
  CircularProgress
} from '@mui/material';
import useAuth from 'src/shared/hooks/useAuth';
import { AuthContextValue } from 'src/shared/contexts/JWTAuthContext';

const LoginForm: FC = () => {
  const { login } = useAuth() as AuthContextValue;
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        terms: true,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Email düzgün daxil edilməyib')
          .max(255)
          .required('Email daxil edilməyib'),
        password: Yup.string().max(255).required('Şifrə daxil edilməyib ')
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.email, values.password);
          // setStatus({ success: true });
          // setSubmitting(false);
        } catch (err) {
          setErrors({ email: err.message, password: err.message });
          // setStatus({ success: false });
          // setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.email && errors.email}
            label="Email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label="Şifrə"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Link
              component={RouterLink}
              to="/recover-password"
              sx={{ textAlign: 'right', width: '100%' }}
            >
              <b>Parolunu unutmusan?</b>
            </Link>
          </Box>

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )}

          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            Daxil ol
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
