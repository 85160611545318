import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import SubmitButton from 'src/shared/components/Submit-button';
import { useAboutUs } from '../hooks/use-about-us';

export const AboutUsForm = () => {
  const { formik, isLoading, isGettingAbout } = useAboutUs();

  return (
    <Card sx={{ p: 2 }}>
      {isGettingAbout ? (
        <Box
          sx={{
            width: '100%',
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Box component="form" onSubmit={formik.handleSubmit}>
          <FormControl
            size="small"
            variant="outlined"
            sx={{ mb: 2 }}
            fullWidth
            error={formik.touched.about && Boolean(formik.errors.about)}
          >
            <InputLabel htmlFor="message">Mesaj</InputLabel>
            <OutlinedInput
              id="about"
              label="Mesaj"
              type="text"
              name="about"
              multiline={true}
              rows={3}
              {...formik.getFieldProps('about')}
            />
            {formik.touched.about && Boolean(formik.errors.about) && (
              <FormHelperText sx={{ ml: 0 }}>
                {formik.errors.about}
              </FormHelperText>
            )}
          </FormControl>

          <Box display="flex" justifyContent="center">
            <SubmitButton load={isLoading} word="Təsdiqlə" />
          </Box>
        </Box>
      )}
    </Card>
  );
};
