import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/shared/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/shared/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AlertProvider } from './shared/contexts/Alert';
import { AuthProvider } from 'src/shared/contexts/JWTAuthContext';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.min.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <ScrollTop />
        <AuthProvider>
          <AlertProvider>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </AlertProvider>
        </AuthProvider>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
