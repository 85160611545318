import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';

export default function BasicModal({
  open,
  children,
  close,
  sx
}: {
  open: boolean;
  children: any;
  close: () => void;
  sx?: any;
}) {
  return (
    <Modal
      open={open ? open : false}
      onClose={() => {
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          '&:focus': {
            outline: 'none'
          },
          '&:focus-within': {
            outline: 'none'
          },
          px: 3,
          py: 2
        }}
      >
        <Box
          sx={{
            boxShadow: 24,
            minWidth: 290,
            ...sx
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
}
