import { MapContainerType, ImageType } from "../types";

export default function billboardMappingImageData(data: MapContainerType) {
  const firstSide = data?.images ? data?.images : [];
  const secondSide = data?.imagesB ? data?.imagesB : [];
  const thirdSide = data?.imagesC ? data?.imagesC : [];
  return [...firstSide, ...secondSide, ...thirdSide].map(
    (image: ImageType) => image.imageUrl
  );
}
