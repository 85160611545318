import { Grid, Skeleton } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import useGetBillboardData from '../hooks/use-get-billboard-data';
import PageTitleWrapper from 'src/shared/components/page-title-wrapper';
import ProductBody from './product-body';

function BillboardInfo() {
  const { data, isError } = useGetBillboardData();
  const navigate = useNavigate();

  if (isError) {
    navigate('/404');
  }

  return (
    <>
      <Helmet>
        <title>{data ? data.type : ''} - MediaBoard</title>
      </Helmet>
      <PageTitleWrapper>
        {/* {!data && (
          <Skeleton
            width="100%"
            height="70px"
            sx={{ transform: `scale(1)`, p: 2 }}
          />
        )} */}
        {/* {data && <PageHeader data={data} />} */}
      </PageTitleWrapper>
      <Grid
        sx={{
          px: { xs: 2, sm: 4 }
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <ProductBody data={data} />
        </Grid>
      </Grid>
    </>
  );
}

export default BillboardInfo;
