import axios from 'src/shared/utils/axios';
import getAuthToken from 'src/shared/utils/getAuthToken';

export const getAbout = async (): Promise<any> => {
  try {
    return await axios.get(`/api/v1/app/inputs/about`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const addAbout = async (data): Promise<any> => {
  try {
    return await axios.post(`/api/v1/app/inputs/about`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
