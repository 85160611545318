import { useState } from 'react';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Box,
  Divider,
  IconButton,
  styled,
  Card,
  CardMedia,
  CardHeader,
  CardContent
} from '@mui/material';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import CloseIcon from '@mui/icons-material/Close';

import BasicModal from 'src/shared/components/Modal/modal';

SwiperCore.use([Navigation, Thumbs]);

const SwipeIndicator = styled(IconButton)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.sm}px) {
        display: none;
    }
    transition: ${theme.transitions.create(['background', 'color'])};
    color: ${theme.colors.primary.main};
    background: ${theme.colors.alpha.white[100]};
    position: absolute;
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    top: 50%;
    margin-top: ${theme.spacing(-1.5)};
    border-radius: 100px;
    z-index: 5;

    &:hover {
      background: ${theme.colors.alpha.white[100]};
      color: ${theme.colors.alpha.black[100]};
    }

    &.MuiSwipe-left {
      left: ${theme.spacing(0.5)};
    }
    
    &.MuiSwipe-right {
      right: ${theme.spacing(0.5)};
    }
`
);

const SwiperWrapper = styled(Box)(
  ({ theme }) => `
  .swiper-wrapper {
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
      }
    } 
  }   
`
);

const SwiperThumbWrapper = styled(Box)(
  ({ theme }) => `

  .swiper-slide {
    width: 140px;
    display: flex;
    padding: 3px;
  }
  
  .swiper-wrapper {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .swiper-thumbs{
    .swiper-slide{
      overflow: hidden;
      max-height: 82px;
      border-radius: ${theme.general.borderRadius};
    }
    .swiper-slide-thumb-active {
      box-shadow: 0 0 0 2px ${theme.colors.primary.main};     
    }
  }

  .swiper-slide-thumb-active { 
    img {
      opacity: 1; 
    }
  }

  &:hover {
    cursor: pointer; 
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${theme.general.borderRadius};
    opacity: 0.7;
    transition: ${theme.transitions.create(['box-shadow', 'opacity'])};
  }
`
);

const Slider = ({ images }: { images: string[] }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [src, setSrc] = useState<string | null>(null);

  const handleModal = () => {
    setIsModal(!isModal);
  };

  return (
    <>
      <Box
        component="span"
        sx={{
          display: { xs: 'none', md: 'inline-block' }
        }}
      >
        <Divider
          absolute
          sx={{
            height: '100%',
            left: 'auto',
            right: 0
          }}
          orientation="vertical"
          flexItem
        />
      </Box>
      <Box p={2}>
        <SwiperWrapper>
          <Box
            sx={{
              position: 'relative',
              mb: 2
            }}
          >
            <Swiper
              thumbs={{ swiper: thumbsSwiper }}
              spaceBetween={15}
              slidesPerView={1}
              autoHeight
              navigation={{
                nextEl: '.MuiSwipe-right',
                prevEl: '.MuiSwipe-left'
              }}
            >
              {images.map((url) => (
                <SwiperSlide key={url}>
                  <img
                    src={url}
                    alt="..."
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      handleModal();
                      setSrc(e.target.currentSrc);
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <SwipeIndicator className="MuiSwipe-root MuiSwipe-left">
              <ChevronLeftTwoToneIcon />
            </SwipeIndicator>
            <SwipeIndicator className="MuiSwipe-root MuiSwipe-right">
              <ChevronRightTwoToneIcon />
            </SwipeIndicator>
          </Box>
          <SwiperThumbWrapper>
            <Swiper
              onSwiper={setThumbsSwiper}
              watchSlidesProgress
              slidesPerView={4}
              spaceBetween={15}
            >
              {images.map((url) => (
                <SwiperSlide key={url}>
                  <img src={url} alt="..." />
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperThumbWrapper>
        </SwiperWrapper>
      </Box>

      <BasicModal open={isModal} close={handleModal} sx={{ width: '100%' }}>
        <Card
          sx={{
            p: 1,
            width: { xs: '95vw', md: '65vw' },
            maxHeight: '70vh',
            overflow: 'hidden',
            pt: 0
          }}
        >
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                onClick={() => {
                  handleModal();
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          />

          <CardMedia
            component="img"
            image={src}
            alt="Paella dish"
            sx={{ objectFit: 'contain', maxHeight: 'calc(70vh - 70px)' }}
          />
        </Card>
      </BasicModal>
    </>
  );
};
export default Slider;
