import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Typography, Box, Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

import Text from 'src/shared/components/Text';
import useAuth from 'src/shared/hooks/useAuth';
import useContainerFollow from '../../hooks/useContainerFollow';
import useContainerUnFollow from '../../hooks/useContainerUnfollow';
import { MapContainerType } from '../../types';

const Price = ({ data }: { data: MapContainerType }) => {
  const [isFollow, setIsFollow] = useState<boolean>(false);
  const { id: containerId } = useParams();

  const { mutate: follow, isLoading: loadingFollowRequest } =
    useContainerFollow(() => {
      setIsFollow(true);
    });
  const { mutate: unFollow, isLoading: loadingUnFollowRequest } =
    useContainerUnFollow(() => {
      setIsFollow(false);
    });

  const auth = useAuth();

  const isUserFollow = () => {
    if (auth?.user?.id) {
      return data.followers.includes(auth.user.id);
    } else {
      return false;
    }
  };

  const handleFollow = () => {
    if (isFollow) {
      unFollow(containerId);
    } else {
      follow(containerId);
    }
  };

  useEffect(() => {
    const follow = isUserFollow();
    setIsFollow(follow);
  }, []);

  return (
    <Box
      alignItems="center"
      justifyContent="space-between"
      sx={{
        display: { xs: 'block', lg: 'flex' }
      }}
    >
      {data.price > 0 && (
        <Box sx={{ my: { xs: 1, sm: 0 } }}>
          <Typography component="div" variant="caption" gutterBottom>
            MONTAJ QİYMƏTİ
          </Typography>

          <Typography
            variant="h3"
            sx={{ span: { fontSize: { xs: 20, md: 25 } } }}
          >
            <Text>{`${data.price} ₼`}</Text>
          </Typography>
        </Box>
      )}
      {data.printMontagePrice > 0 && (
        <Box sx={{ my: { xs: 1, sm: 0 } }}>
          <Typography component="div" variant="caption" gutterBottom>
            MONTAJ QİYMƏTİ
          </Typography>

          <Typography
            variant="h3"
            sx={{ span: { fontSize: { xs: 20, md: 25 } } }}
          >
            <Text>{`${data.printMontagePrice} ₼`}</Text>
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          my: { xs: 2, lg: 0 },
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {auth.isAuthenticated && (
          <Tooltip
            title={isFollow ? 'Bildirişi deaktiv et' : 'Boş olarsa mənə bildir'}
          >
            <Box
              sx={(theme) => ({
                border: `1px solid ${theme.colors.error.main}`,
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: { xs: 38, md: 48 },
                height: { xs: 38, md: 48 },
                mr: 1,
                cursor: 'pointer'
              })}
            >
              {isFollow && !loadingUnFollowRequest && !loadingFollowRequest && (
                <NotificationsActiveIcon color="error" onClick={handleFollow} />
              )}
              {!isFollow &&
                !loadingFollowRequest &&
                !loadingUnFollowRequest && (
                  <NotificationsNoneOutlinedIcon
                    color="error"
                    onClick={handleFollow}
                  />
                )}

              {(loadingFollowRequest || loadingUnFollowRequest) && (
                <CircularProgress color="error" size={20} thickness={5} />
              )}
            </Box>
          </Tooltip>
        )}
        <Box
          sx={{
            border: '1px solid',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: { xs: 180, md: 215 },
            height: { xs: 38, md: 48 }
          }}
        >
          <PhoneIcon sx={{ mr: 1 }} />
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 700, fontSize: { xs: 12, md: 15 } }}
          >
            +994 70 711 11 86
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default Price;
