import { Box, Card, Typography, Container, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import LoginForm from '../components/LoginForm';
import Logo from 'src/shared/components/Logo';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  padding: 20px;
  justify-content: center;
`
);

function LoginBasic() {
  return (
    <>
      <Helmet>
        <title>Giriş - Mediaboard</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Box sx={{ width: { sm: '546px', xs: '100%' } }}>
            <Logo />
            <Card
              sx={{
                mt: 3,
                paddingLeft: { sm: '36px', xs: '22px' },
                paddingRight: { sm: '36px', xs: '22px' },
                paddingTop: { sm: '45px', xs: '22px' },
                paddingBottom: '27px'
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  Daxil ol
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  Hesabınıza daxil olmaq üçün aşağıdakı xanaları doldurun
                </Typography>
              </Box>
              <LoginForm />
            </Card>
          </Box>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default LoginBasic;
