import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, styled } from "@mui/material";

interface PageTitleWrapperProps {
  children?: ReactNode;
}

const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children }) => {
  return (
    <>
      <Box sx={{ p: { xs: 2, sm: 4 } }} className="MuiPageTitle-wrapper">
        {children}
      </Box>
    </>
  );
};

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitleWrapper;
