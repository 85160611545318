import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function SubmitButton({
  load,
  word,
  sx,
  callback
}: {
  load: Boolean;
  word: string;
  sx?: any;
  callback?: () => void;
}) {
  return (
    <>
      {load ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '66px' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Button
          sx={{
            px: 4,
            ...sx
          }}
          variant="contained"
          type="submit"
          onClick={() => {
            callback?.();
          }}
        >
          {word}
        </Button>
      )}
    </>
  );
}
