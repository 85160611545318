import { useMutation } from "react-query";
import { containerUnFollow } from "../services";
import { useSnackbar } from "notistack";

export default function useContainerUnfollow(callback: () => void) {
  const { enqueueSnackbar } = useSnackbar();
  const query = useMutation("get-container-un-follow", containerUnFollow, {
    onSuccess: ({ status, message }: { status: any; message: string }) => {
      if (status === "success" || status === 200) {
        enqueueSnackbar("Bu daşıyıcı ilə bağlı bildiriş deaktiv edildi", {
          variant: "success",
        });
        callback();
      } else {
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || "Bilinməyən xəta";
      enqueueSnackbar(errorMsg, {
        variant: "error",
      });
    },
  });
  return query;
}
