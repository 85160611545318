import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import aboutUsRouter from 'src/modules/about-us/router';
import AboutUsRouter from 'src/modules/about-us/router';
import BillboardDetails from 'src/modules/containers/pages/billboard-details';

import SuspenseLoader from 'src/shared/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const ContainersRouter = Loader(
  lazy(() => import('src/modules/containers/router/containerRouter'))
);
const Containers = Loader(
  lazy(() => import('src/modules/containers/pages/containers'))
);
const AddContainer = Loader(
  lazy(() => import('src/modules/containers/pages/add-container'))
);
const EditContainer = Loader(
  lazy(() => import('src/modules/containers/pages/edit-container'))
);
const ContainerInfo = Loader(
  lazy(() => import('src/modules/containers/pages/container-info'))
);
const ContainerFollowers = Loader(
  lazy(() => import('src/modules/containers/pages/container-followers'))
);
const UsersRouter = Loader(lazy(() => import('src/modules/users/router')));
const Users = Loader(lazy(() => import('src/modules/users/pages/users')));
const AddUser = Loader(lazy(() => import('src/modules/users/pages/add-user')));
const EditUser = Loader(
  lazy(() => import('src/modules/users/pages/edit-user'))
);
const Compaines = Loader(
  lazy(() => import('src/modules/compaines/containers/companies'))
);
const Profile = Loader(lazy(() => import('src/modules/profile/pages/profile')));
const OrderRouter = Loader(lazy(() => import('src/modules/orders/router')));
const Orders = Loader(lazy(() => import('src/modules/orders/pages/orders')));

const dashboardsRoutes = [
  {
    path: '/',
    element: <Navigate to="/dashboard/containers" />
  },
  {
    path: '/containers',
    element: <ContainersRouter />,
    children: [
      {
        path: '/',
        element: <Containers />
      },
      {
        path: ':id',
        element: <ContainersRouter />,
        children: [
          {
            path: '/',
            element: <ContainerInfo />
          },
          {
            path: '/edit',
            element: <EditContainer />
          },
          {
            path: '/followers',
            element: <ContainerFollowers />
          }
        ]
      },
      {
        path: '/details/:id',
        element: <BillboardDetails />
      }
    ]
  },
  aboutUsRouter,
  {
    path: '/users',
    element: <UsersRouter />,
    children: [
      {
        path: '/',
        element: <Users />
      },
      {
        path: '/add-user',
        element: <AddUser />
      },
      {
        path: ':id',
        element: <EditUser />
      }
    ]
  },
  {
    path: '/add-container',
    element: <AddContainer />
  },
  {
    path: '/compaines',
    element: <Compaines />
  },
  {
    path: '/orders',
    element: <OrderRouter />,
    children: [
      {
        path: '/',
        element: <Orders />
      }
    ]
  },
  {
    path: '/profile',
    element: <Profile />
  }
];

export default dashboardsRoutes;
