import { Helmet } from 'react-helmet-async';
import BillboardInfo from '../components/billboard-info';

function BillboardDetails() {
  return (
    <>
      <Helmet>
        <title>Billboard - MediaBoard</title>
      </Helmet>
      <BillboardInfo />
    </>
  );
}

export default BillboardDetails;
