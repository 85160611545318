import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getBillboardConatinerData } from '../services';
import { MapContainerType } from '../types';
import { useSnackbar } from 'notistack';

export default function useGetBillboardData() {
  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery(
    ['get-container-data', id],
    () => getBillboardConatinerData(id),
    {
      select: (data: any) => {
        const container: MapContainerType = data.data.data;
        return container;
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Bilinməyən xəta';
        enqueueSnackbar(errorMsg, {
          variant: 'error'
        });
      }
    }
  );
  return query;
}
