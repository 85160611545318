import { AxiosResponse } from 'axios';
import { OrderType } from 'src/modules/orders/types';
import axios from 'src/shared/utils/axios';
import getAuthToken from 'src/shared/utils/getAuthToken';
import { ContainerType } from '../types';

export const getOwner = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/users?containerOwners=1`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const getContainers = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`api/v1/containers`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
export const getAddContainerPageData = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`api/v1/app/inputs/add-container`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
export const uploadImage = async (data: {
  image: string | ArrayBuffer;
  folderName: string;
  containerCode: string;
}): Promise<AxiosResponse> => {
  try {
    const { data: newData } = await axios.post(
      `api/v1/app/upload-image`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken()
        }
      }
    );
    return newData;
  } catch (e) {
    return e;
  }
};
export const deleteImage = async (data: { publicId: string }): Promise<any> => {
  try {
    const { data: newData } = await axios.post(
      `api/v1/app/remove-image`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken()
        }
      }
    );
    return newData;
  } catch (e) {
    return e;
  }
};
export const getConatinerData = async (
  id: string | number
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/containers/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
export const getBillboardConatinerData = async (
  id: string | number
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/client/containers/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
export const addContainer = async (
  data: Partial<ContainerType>
): Promise<any> => {
  try {
    const { data: newData } = await axios.post(`/api/v1/containers`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
    return newData;
  } catch (e) {
    return e;
  }
};
export const getOrderData = async (
  id: string | number
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/app/inputs/add-order/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
export const addOrder = async (data: Partial<OrderType>): Promise<any> => {
  try {
    const { data: newData } = await axios.post(`/api/v1/orders`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
    return newData;
  } catch (e) {
    return e;
  }
};
export const updateContainerData = async (container: {
  id: string | number;
  data: Partial<ContainerType>;
}): Promise<any> => {
  try {
    const { data: newData } = await axios.patch(
      `/api/v1/containers/${container.id}`,
      container.data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken()
        }
      }
    );
    return newData;
  } catch (e) {
    return e;
  }
};
export const archiveContainer = async (id: string | number): Promise<any> => {
  try {
    return await axios.post(`/api/v1/containers/deactivate/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
export const unArchiveContainer = async (id: string | number): Promise<any> => {
  try {
    return await axios.post(`/api/v1/containers/activate/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
export const getFollowers = async (
  id: string | number
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/containers/followers/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
export const deleteContainer = async (id: string): Promise<any> => {
  try {
    const { data: newData } = await axios.delete(`/api/v1/containers/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
    return newData;
  } catch (e) {
    return e;
  }
};

export const getAllContainers = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/containers`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const getPageDatas = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/app/inputs/add-container`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const getAllContainersByParams = async (
  params: any
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/client/containers/all${params}`);
  } catch (e) {
    return e;
  }
};

export const containerFollow = async (id: string | string[]): Promise<any> => {
  try {
    return await axios.post(`/api/v1/client/containers/follow/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
export const containerUnFollow = async (
  id: string | string[]
): Promise<any> => {
  try {
    return await axios.post(`/api/v1/client/containers/unfollow/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const expireDateService = async ({
  expireDate,
  containerId
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(
      `/api/v1/containers/update-expire-date/${containerId}`,
      {
        expireDate
      },
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken()
        }
      }
    );
  } catch (e) {
    return e;
  }
};
