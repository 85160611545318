import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function BillboardMarker({ lat, lng }: { lat: number; lng: number }) {
  return (
    <>
      <LocationOnIcon
        sx={{
          fontSize: 30,
          color: "#c5001A",
          position: `absolute`,
          top: 0,
          transform: `translate(-50%, -100%)`,
        }}
      />
    </>
  );
}
