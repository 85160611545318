import { useMutation, useQuery } from 'react-query';
import { addAbout, getAbout } from '../services';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useFormik } from 'formik';

export const useAboutUs = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    isLoading: isGettingAbout,
    refetch
  } = useQuery('get-about', getAbout, {
    select: ({ data }) => {
      if (data?.status === 'success') {
        return data;
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Bilinməyən xəta';
      enqueueSnackbar(errorMsg, {
        variant: 'error'
      });
    }
  });

  const { mutate, isLoading } = useMutation('add-about', addAbout, {
    onSuccess: ({ status, message }: { status: any; message: string }) => {
      if (status === 'success' || status === 200) {
        enqueueSnackbar('Uğurla əlavə edildi', {
          variant: 'success'
        });
        refetch();
      } else {
        enqueueSnackbar(message, {
          variant: 'error'
        });
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Bilinməyən xəta';
      enqueueSnackbar(errorMsg, {
        variant: 'error'
      });
    }
  });

  const validationSchema = Yup.object().shape({
    about: Yup.string().required('Məlumat daxil edilməyib')
  });

  const formik = useFormik({
    initialValues: {
      about: data?.about || ''
    },
    onSubmit: (values) => {
      mutate(values);
    },
    validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    isLoading,
    isGettingAbout
  };
};
