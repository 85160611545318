import { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Card, Box } from '@mui/material';
import BillboardMarker from './billboard-marker';
import { MAP_STYLE } from '../constant';

function AdditionalInfo({ coordinates }: { coordinates: number[] }) {
  const [currentZoom, setCurrentZoom] = useState(13);
  return (
    <Card>
      <Box sx={{ width: '100%', minHeight: '600px', height: '600px', p: 2 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_PUBLIC_GOOGLE_MAP_API_KEY }}
          defaultCenter={{ lat: coordinates[0], lng: coordinates[1] }}
          defaultZoom={13}
          zoom={currentZoom}
          margin={[50, 50, 50, 50]}
          options={(maps) => ({
            disableDefaultUI: true,
            streetViewControl: false,
            fullscreenControl: true,
            styles: MAP_STYLE,
            gestureHandling: 'greedy',
            disableDoubleClickZoom: true,
            maxZoom: 18,
            mapTypeControl: true,
            mapTypeControlOptions: {
              position: maps.ControlPosition.TOP_LEFT,
              mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID
              ]
            },

            zoomControl: true,
            clickableIcons: false
          })}
          onChange={(e: any) => {
            setCurrentZoom(e.zoom);
          }}
        >
          {currentZoom >= 10 && (
            <BillboardMarker
              lat={Number(coordinates[0])}
              lng={Number(coordinates[1])}
            />
          )}
        </GoogleMapReact>
      </Box>
    </Card>
  );
}

export default AdditionalInfo;
