import {
  Box,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";

import QrCodeIcon from "@mui/icons-material/QrCode";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import TungstenIcon from "@mui/icons-material/Tungsten";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import Text from "src/shared/components/Text";
import { MapContainerType } from "src/modules/containers/types";

function GeneralDatas({ data }: { data: MapContainerType }) {
  const theme = useTheme();

  return (
    <List sx={{ p: 0 }}>
      <ListItem>
        <ListItemAvatar
          sx={{
            mr: 2,
            display: "flex",
            alignItems: "center",
            minWidth: 0,
            opacity: 0.3,
          }}
        >
          <QrCodeIcon
            sx={{
              fontSize: `${theme.typography.pxToRem(28)}`,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h4" sx={{ fontSize: { xs: 13, sm: 16 } }}>
              Kod
            </Typography>
          }
        />
        <Box>
          <Typography
            variant="h5"
            sx={{ opacity: 0.8, fontSize: { xs: 13, sm: 16 } }}
          >
            {data.code}
          </Typography>
        </Box>
      </ListItem>
      <Divider />
      <ListItem
        sx={{
          px: 2,
          py: 1.5,
        }}
      >
        <ListItemAvatar
          sx={{
            mr: 2,
            display: "flex",
            alignItems: "center",
            minWidth: 0,
            opacity: 0.3,
          }}
        >
          <DesktopWindowsIcon
            sx={{
              fontSize: `${theme.typography.pxToRem(28)}`,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h4" sx={{ fontSize: { xs: 13, sm: 16 } }}>
              Növ
            </Typography>
          }
        />
        <Box>
          <Typography
            variant="h5"
            sx={{ opacity: 0.8, fontSize: { xs: 13, sm: 16 } }}
          >
            {data.type}
          </Typography>
        </Box>
      </ListItem>
      <Divider />
      <ListItem
        sx={{
          px: 2,
          py: 1.5,
        }}
      >
        <ListItemAvatar
          sx={{
            mr: 2,
            display: "flex",
            alignItems: "center",
            minWidth: 0,
            opacity: 0.3,
          }}
        >
          <LocationCityIcon
            sx={{
              fontSize: `${theme.typography.pxToRem(28)}`,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h4" sx={{ fontSize: { xs: 13, sm: 16 } }}>
              Şəhər / Rayon
            </Typography>
          }
        />
        <Box>
          <Typography
            variant="h5"
            sx={{ opacity: 0.8, fontSize: { xs: 13, sm: 16 } }}
          >
            {data.city}
          </Typography>
        </Box>
      </ListItem>
      <Divider />
      <ListItem
        sx={{
          px: 2,
          py: 1.5,
        }}
      >
        <ListItemAvatar
          sx={{
            mr: 2,
            display: "flex",
            alignItems: "center",
            minWidth: 0,
            opacity: 0.3,
          }}
        >
          <TungstenIcon
            sx={{
              fontSize: `${theme.typography.pxToRem(28)}`,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h4" sx={{ fontSize: { xs: 13, sm: 16 } }}>
              İşıqlandırma
            </Typography>
          }
        />
        <Box>
          <Typography variant="h5">
            {data.lightened && <Text color="success">İşıqlı</Text>}
            {!data.lightened && <Text color="error">İşıqsız</Text>}
          </Typography>
        </Box>
      </ListItem>
      <Divider />
      <ListItem
        sx={{
          px: 2,
          py: 1.5,
        }}
      >
        <ListItemAvatar
          sx={{
            mr: 2,
            display: "flex",
            alignItems: "center",
            minWidth: 0,
            opacity: 0.3,
          }}
        >
          <AspectRatioIcon
            sx={{
              fontSize: `${theme.typography.pxToRem(28)}`,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h4" sx={{ fontSize: { xs: 13, sm: 16 } }}>
              Ölçü
            </Typography>
          }
        />
        <Box>
          <Typography
            variant="h5"
            sx={{ opacity: 0.8, fontSize: { xs: 13, sm: 16 } }}
          >
            {`${data.height / 100} x ${data.width / 100}m`}
          </Typography>
        </Box>
      </ListItem>
      <Divider />
      <ListItem
        sx={{
          px: 2,
          py: 1.5,
        }}
      >
        <ListItemAvatar
          sx={{
            mr: 2,
            display: "flex",
            alignItems: "center",
            minWidth: 0,
            opacity: 0.3,
          }}
        >
          <ArtTrackIcon
            sx={{
              fontSize: `${theme.typography.pxToRem(28)}`,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h4" sx={{ fontSize: { xs: 13, sm: 16 } }}>
              Üz
            </Typography>
          }
        />
        <Box>
          <Typography
            variant="h5"
            sx={{ opacity: 0.8, fontSize: { xs: 13, sm: 16 } }}
          >
            {data.sideCount}
          </Typography>
        </Box>
      </ListItem>
    </List>
  );
}

export default GeneralDatas;
